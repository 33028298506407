.App {
  text-align: center;
}

.App-center{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  z-index: 1;
  border-radius: 21px;
  margin-top: 10px;
}

.App-welcome {
  height: 50vmin;
  z-index: 1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.App-link {
  color: #09d3ac;
  z-index: 1;
}

.App-background-video{
  width: 2000px !important;
  height: 2000 !important;
  float: left;
  top: 0;
  padding: none;
  opacity: 0.2;
  position: fixed;
  z-index: 0;
}

.App-donate-btn{
  width: 60%;
  border-radius: 100px !important;
  background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
  background-size: 400% 400%;
  -webkit-animation: Gradient 35s ease infinite;
  -moz-animation: Gradient 35s ease infinite;
  animation: Gradient 35s ease infinite;
  color: 'white';
}

.App-underconstruction{
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.background-grad {
  width: 100wh;
  height: 100%;
  color: #fff;
  background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
  }

.animated-background {
  width: 100wh;
  height: 190vh;
  background-size: cover;
  background-position: center;
  text-align: center;
}

.paused {
  -webkit-animation-play-state: paused; /* Safari 4.0 - 8.0 */
  animation-play-state: paused;
}

@-webkit-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
}
