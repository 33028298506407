/*-- Convert px to rem --*/

@function parseInt($n) {
    @return $n / ($n * 0 + 1);
  }
  
  
  @mixin rem($property, $values) {
    $px: ();
    $rem: ();
    @each $value in $values {
      @if $value==0 or $value==auto {
        $px: append($px, $value);
        $rem: append($rem, $value);
      }
      @else {
        $unit: unit($value);
        $val: parseInt($value);
        @if $unit=="px" {
          $px: append($px, $value);
          $rem: append($rem, ($val / 16 + rem));
        }
        @if $unit=="rem" {
          $px: append($px, ($val * 16 + px));
          $rem: append($rem, $value);
        }
      }
    }
    @if $px==$rem {
      #{$property}: $px;
    }
    @else {
      #{$property}: $px;
      #{$property}: $rem;
    }
  }