*{
    box-sizing: border-box;
}

body{
    background: #FFF;
    font-family: 'Open Sans', sans-serif;
}

.form-row{
    display: block;
    @include rem(margin-bottom, 20px);

    &.inline{
        display: flex;
        flex-direction: column;
    }
}

.form-row:focus{
  border-color: $brand__color;
}

.title{
    color: $brand__secondary;
    display: block;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
}


label{
    color: $brand__secondary;
    display: block;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
}

input[type="text"], select{
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #FFF;
    border: 1px solid $brand__color;
    border-radius: 5px;
    font-size: 1rem;
    padding: 0.625rem 0.75rem;
    width: 100%;
}

select {
    background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22…%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem) center;
    background-size: 0.6875rem;
    color: #AFB1BA;
    padding-right: 2.5rem;
}

.label--radio {
  cursor: pointer;
  line-height: 135%;
  margin: .5rem;
  position: relative;
}

input[type='radio'] {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #FFF;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:after {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #FFF;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: $brand__secondary;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

button{
    cursor: pointer;
    padding: 0;
    background-color: $brand__color;
    color: $brand__secondary;
    border: 0;
    white-space: nowrap;
    padding: 1.0625rem 2.5rem;
    border: none;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    font-size: 1rem;
    line-height: 19px;
    line-height: 1.1875rem;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    border: 1px solid transparent;
    text-align: center;

    &:disabled{
        background-color: #dfe0e4;
        color: #AFB1BA;
        opacity: 0.7;
    }
}

@media only screen and (min-width:1024px) {
    .form-row{
        &.inline{
            flex-direction: row;
        }
    }
}
