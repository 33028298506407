.Canvas-center {
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
}

.react-pdf__Page__canvas {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
